import { defineComponent, h, onMounted, ref, resolveComponent } from "vue";
import { RouterLink, useRoute } from "vue-router";

import {
  CBadge,
  CSidebarNav,
  CNavItem,
  CNavGroup,
  CNavTitle,
} from "@coreui/vue";
import nav from "@/_nav.js";
import nav_dosen from "@/_nav-dosen.js";
import routes_mahasiswa from "@/router/role/mahasiswa";
import routes_camaba from "@/router/role/camaba";
import routes_rektor from "@/router/role/rektor";
import routes_marketing from "@/router/role/marketing";
import routes_baak from "@/router/role/baak";
import routes_wakil_rektor from "@/router/role/warek";
import routes_kaprodi from "@/router/role/kaprodi";
import routes_superadmin from "@/router/role/superadmin";
import routes_financial from "@/router/role/financial";
import routes_nonmahasiswa from "@/router/role/nonmahasiswa";
import routes_fakultas from "@/router/role/fakultas";
import routes_bpm from "@/router/role/bpm";

const normalizePath = (path) =>
  decodeURI(path)
    .replace(/#.*$/, "")
    .replace(/(index)?\.(html)$/, "");

const isActiveLink = (route, link) => {
  if (link === undefined) {
    return false;
  }

  if (route.hash === link) {
    return true;
  }

  const currentPath = normalizePath(route.path);
  const targetPath = normalizePath(link);

  return currentPath === targetPath;
};

const isActiveItem = (route, item) => {
  if (isActiveLink(route, item.to)) {
    return true;
  }

  if (item.items) {
    return item.items.some((child) => isActiveItem(route, child));
  }

  return false;
};

function mapRoutesToNavItems(routes) {
  return routes
    .filter((route) => !/:|\/\?/.test(route.path))
    .map((route) => {
      if (route.children && route.children.length > 0) {
        return {
          component: "CNavGroup",
          name: route.name,
          to: route.path,
          items: mapRoutesToNavItems(route.children), // Rekursif untuk children
        };
      }

      return {
        component: "CNavItem",
        name: route.name,
        to: route.path,
      };
    });
}

const AppSidebarNav = defineComponent({
  name: "AppSidebarNav",
  components: {
    CNavItem,
    CNavGroup,
    CNavTitle,
  },
  setup() {
    const route = useRoute();
    const firstRender = ref(true);

    onMounted(() => {
      firstRender.value = false;
    });

    const renderItem = (item) => {
      if (item.items) {
        return h(
          CNavGroup,
          {
            ...(firstRender.value && {
              visible: item.items.some((child) => isActiveItem(route, child)),
            }),
          },
          {
            togglerContent: () => [
              // h(resolveComponent("CIcon"), {
              //   customClassName: "nav-icon",
              //   name: item.icon,
              // }),
              item.name,
            ],
            default: () => item.items.map((child) => renderItem(child)),
          }
        );
      }

      return item.to
        ? h(
            RouterLink,
            {
              to: item.to,
              custom: true,
            },
            {
              default: (props) =>
                h(
                  resolveComponent(item.component),
                  {
                    active: props.isActive,
                    href: props.href,
                    onClick: () => props.navigate(),
                  },
                  {
                    default: () => [
                      // item.icon &&
                      //   h(resolveComponent("CIcon"), {
                      //     customClassName: "nav-icon",
                      //     name: item.icon,
                      //   }),
                      item.name,
                      // item.badge &&
                      //   h(
                      //     CBadge,
                      //     {
                      //       class: "ms-auto",
                      //       color: item.badge.color,
                      //     },
                      //     {
                      //       default: () => item.badge.text,
                      //     }
                      //   ),
                    ],
                  }
                ),
            }
          )
        : h(
            resolveComponent(item.component),
            {},
            {
              default: () => item.name,
            }
          );
    };

    return () => {
      const role = sessionStorage.getItem("role")
        ? sessionStorage.getItem("role").toLowerCase()
        : "";
      const jenis_akun = sessionStorage.getItem("akun")
        ? sessionStorage.getItem("akun").toLowerCase()
        : "";

      const navMap = {
        bpm: mapRoutesToNavItems(routes_bpm),
        mahasiswa: mapRoutesToNavItems(routes_mahasiswa),
        camaba: mapRoutesToNavItems(routes_camaba),
        baak: mapRoutesToNavItems(routes_baak),
        marketing: mapRoutesToNavItems(routes_marketing),
        rektor: mapRoutesToNavItems(routes_rektor),
        "wakil rektor": mapRoutesToNavItems(routes_wakil_rektor),
        kaprodi: mapRoutesToNavItems(routes_kaprodi),
        dekan: mapRoutesToNavItems(routes_kaprodi),
        sekprodi: mapRoutesToNavItems(routes_kaprodi),
        superadmin: mapRoutesToNavItems(routes_superadmin),
        keuangan: mapRoutesToNavItems(routes_financial),
        "fakultas informatika dan pariwisata":
          mapRoutesToNavItems(routes_fakultas),
        "fakultas bisnis": mapRoutesToNavItems(routes_fakultas),
        "fakultas vokasi": mapRoutesToNavItems(routes_fakultas),
        "program vokasi": mapRoutesToNavItems(routes_fakultas),
      };

      // Set default navigation for non-students
      const defaultNav = mapRoutesToNavItems(routes_nonmahasiswa);

      // Choose the appropriate navigation based on the role
      let renderNav = navMap[role] || defaultNav;

      if (jenis_akun === "dosen") {
        renderNav = [...renderNav, ...nav_dosen];
      }

      return h(
        CSidebarNav,
        {},
        {
          // default: () => renderNav.map((item) => renderItem(item)),
          // default: () =>
          //   renderNav
          //     .sort((a, b) => a.name.localeCompare(b.name))
          //     .map((item) => renderItem(item)),

          default: () =>
            renderNav
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((item) => {
                if (item.items) {
                  item.items.sort((a, b) => a.name.localeCompare(b.name));
                }
                return renderItem(item);
              }),
        }
      );
    };
  },
});
export { AppSidebarNav };
