import LayoutCard from "@/layouts/LayoutCard";
import { h, resolveComponent } from "vue";

const routes_bpm = [
  //akun
  {
    path: "/akun",
    name: "Akun",
    component: LayoutCard,
    redirect: "/akun/beranda",
    children: [
      {
        path: "/akun/beranda",
        name: "Beranda Akun",
        component: () => import("@/views/akun/Beranda.vue"),
      },
      {
        path: "/akun/profilku",
        name: "Profilku",
        component: {
          render() {
            return h(resolveComponent("router-view"));
          },
        },
        redirect: "/akun/profilku/editprofil",
        children: [
          {
            path: "/akun/profilku/ubahpassword",
            name: "Ubah Password",
            component: () => import("@/views/akun/UbahPassword.vue"),
          },
        ],
      },
    ],
  },

  //kuesioner
  {
    path: "/kuesioner",
    name: "Kuesioner",
    component: LayoutCard,
    redirect: "/kuesioner/beranda",
    children: [
      {
        path: "/kuesioner/beranda",
        name: "Beranda Kuesioner",
        component: () => import("@/views/kuesioner/Beranda.vue"),
      },
      {
        path: "/kuesioner/daftar-kuesioner",
        name: "Daftar Kuesioner",
        component: () => import("@/views/kuesioner/LihatKuesioner.vue"),
      },
      {
        path: "/kuesioner/detail-kuesioner/:id",
        name: "Detail Kuesioner",
        component: () => import("@/views/kuesioner/DetailKuesioner.vue"),
      },
      {
        path: "/kuesioner/laporan-kuesioner",
        name: "Laporan Kuesioner",
        component: () => import("@/views/kuesioner/LaporanKuesioner.vue"),
      },
      {
        path: "/kuesioner/laporan-kuesioner-dosen",
        name: "Laporan Kuesioner Per Dosen",
        component: () => import("@/views/kuesioner/LaporanKuesionerDosen.vue"),
      },
    ],
  },
];

export default routes_bpm;
