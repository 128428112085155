<template>
  <CHeader position="sticky" class="mb-md-4 bg-light">
    <CContainer fluid class="d-md-flex px-1">
      <!-- Toggler dan Logo untuk Layar Medium dan Lebih Besar -->
      <div class="d-flex">
        <CHeaderToggler
          class="ps-1 d-none d-md-block"
          @click="$store.commit('toggleSidebar')"
        >
          <CIcon icon="cil-menu" size="lg" style="color: gray" />
        </CHeaderToggler>
        <CHeaderNav class="d-none d-md-block">
          <CNavItem>
            <CNavLink class="text-dark" href="/">
              <img
                src="../assets/images/kisLogoUngu.png"
                style="height: 40px"
              />
            </CNavLink>
          </CNavItem>
        </CHeaderNav>
      </div>

      <!-- Logo untuk Layar Kecil -->
      <div class="d-md-none w-100 d-flex justify-content-center">
        <CHeaderNav>
          <CNavItem>
            <CNavLink class="text-dark" href="/">
              <img
                src="../assets/images/kisLogoUngu.png"
                style="height: 40px"
              />
            </CNavLink>
          </CNavItem>
        </CHeaderNav>
      </div>

      <!-- Dropdown, Badge, dan Icon untuk Layar Medium dan Lebih Besar -->
      <div class="d-none d-md-block">
        <CHeaderNav class="mx-auto">
          <!-- <div class="text-dark fw-bold d-flex align-items-center me-2">
            <CBadge color="secondary py-2 mx-2">2223 / 1</CBadge>
          </div> -->
          <!-- Dropdown untuk Grup Pengguna -->
          <CDropdown
            color="transparant"
            alignment="end"
            class="me-2"
            variant="nav-item"
          >
            <CDropdownToggle @click.prevent>
              <CIcon icon="cil-list" size="lg" style="color: #472e7c" />
            </CDropdownToggle>
            <CDropdownMenu alignment="end">
              <CDropdownHeader style="font-weight: 500"
                >Grup Pengguna</CDropdownHeader
              >
              <CDropdownItem active style="cursor: pointer">{{
                role
              }}</CDropdownItem>
            </CDropdownMenu>
          </CDropdown>

          <!-- Dropdown untuk Envelope -->
          <CDropdown
            color="transparant"
            alignment="end"
            class="me-2"
            variant="nav-item"
          >
            <CDropdownToggle @click.prevent>
              <CBadge color="secondary" style="margin-right: 0.5rem">0</CBadge>
              <CIcon
                icon="cil-envelope-open"
                size="lg"
                style="color: #472e7c"
              />
            </CDropdownToggle>
            <CDropdownMenu></CDropdownMenu>
          </CDropdown>

          <!-- Dropdown untuk Bell -->
          <CDropdown
            color="transparant"
            alignment="end"
            class="me-2"
            variant="nav-item"
          >
            <CDropdownToggle @click.prevent>
              <CBadge color="secondary" style="margin-right: 0.5rem">0</CBadge>
              <CIcon icon="cil-bell" size="lg" style="color: #472e7c" />
            </CDropdownToggle>
            <CDropdownMenu alignment="end" class="menu">
              <CDropdownHeader style="font-weight: 500"
                >Notifikasi</CDropdownHeader
              >
              <CHeaderDivider />
              <CDropdownItem class="menu">...</CDropdownItem>
              <CDropdownItem class="menu">...</CDropdownItem>
              <CDropdownItem class="menu">...</CDropdownItem>
              <CDropdownDivider />
              <CDropdownItem class="menu">Lihat Semua Notifikasi</CDropdownItem>
            </CDropdownMenu>
          </CDropdown>

          <CDropdown class="me-2" variant="nav-item">
            <CDropdownToggle
              placement="bottom-end"
              class="p-0 border-0"
              :caret="false"
              @click.prevent
            >
              <CAvatar :src="avatar" size="md" />
            </CDropdownToggle>
            <CDropdownMenu>
              <!-- <CDropdownItem
                class="custom-dropdown-item"
                style="cursor: pointer"
              >
                <CIcon icon="cilUser" /> Ubah Profil
              </CDropdownItem>
              <CDropdownItem
                class="custom-dropdown-item"
                style="cursor: pointer"
              >
                <CIcon icon="cilLanguage" /> ENG
              </CDropdownItem> -->
              <CDropdownItem
                class="custom-dropdown-item"
                style="cursor: pointer"
                @click="
                  () => {
                    this.$router.replace('/akun/profilku/ubahpassword');
                  }
                "
              >
                <CIcon icon="cilSettings" /> Pengaturan Akun
              </CDropdownItem>
              <!-- <CDropdownItem
                class="custom-dropdown-item"
                style="cursor: pointer"
              >
                <CIcon icon="cilBell" /> Pengaturan Notifikasi
              </CDropdownItem> -->
              <CDropdownItem
                class="custom-dropdown-item"
                style="cursor: pointer; background-color: activeColor"
                @click="logout"
              >
                <CIcon icon="cil-lock-locked" /> Logout
              </CDropdownItem>
            </CDropdownMenu>
          </CDropdown>
        </CHeaderNav>
      </div>

      <!-- Toggler, Badge, dan Icon untuk Layar Kecil -->
      <div class="d-flex justify-content-between w-100 d-md-none">
        <div>
          <CHeaderToggler
            class="ps-1 d-md-none"
            @click="$store.commit('toggleSidebar')"
          >
            <CIcon icon="cil-menu" size="lg" style="color: gray" />
          </CHeaderToggler>
        </div>
        <div>
          <CHeaderNav class="mx-auto">
            <!-- <div class="text-dark fw-bold d-flex align-items-center me-2">
              <CBadge color="secondary py-2 mx-2">2223 / 1</CBadge>
            </div> -->
            <!-- Dropdown untuk Grup Pengguna -->
            <!-- <CDropdown color="transparant" alignment="end" class="me-2">
              <CDropdownToggle>
                <CIcon icon="cil-list" size="lg" style="color: #472e7c" />
              </CDropdownToggle>
              <CDropdownMenu alignment="end" class="me-2">
                <CDropdownHeader style="font-weight: 500"
                  >Grup Pengguna</CDropdownHeader
                >
                <CDropdownItem active>Mahasiswa</CDropdownItem>
              </CDropdownMenu>
            </CDropdown> -->

            <!-- Dropdown untuk Envelope -->
            <!-- <CDropdown color="transparant" alignment="end" class="me-2">
              <CDropdownToggle>
                <CBadge color="secondary" style="margin-right: 0.5rem"
                  >0</CBadge
                >
                <CIcon
                  icon="cil-envelope-open"
                  size="lg"
                  style="color: #472e7c"
                />
              </CDropdownToggle>
              <CDropdownMenu></CDropdownMenu>
            </CDropdown> -->

            <!-- Dropdown untuk Bell -->
            <!-- <CDropdown color="transparant" alignment="end" class="me-2">
              <CDropdownToggle>
                <CBadge color="secondary" style="margin-right: 0.5rem"
                  >0</CBadge
                >
                <CIcon icon="cil-bell" size="lg" class="text-primary" />
              </CDropdownToggle>
              <CDropdownMenu alignment="end" class="menu">
                <CDropdownHeader style="font-weight: 500"
                  >Notifikasi</CDropdownHeader
                >
                <CHeaderDivider />
                <CDropdownItem class="menu">...</CDropdownItem>
                <CDropdownItem class="menu">...</CDropdownItem>
                <CDropdownItem class="menu">...</CDropdownItem>
                <CDropdownDivider />
                <CDropdownItem class="menu"
                  >Lihat Semua Notifikasi</CDropdownItem
                >
              </CDropdownMenu>
            </CDropdown> -->

            <CDropdown color="transparant" alignment="end" variant="nav-item">
              <CDropdownToggle
                placement="bottom-end"
                class="py-0 border-0"
                :caret="false"
              >
                <CAvatar :src="avatar" size="md" />
              </CDropdownToggle>
              <CDropdownMenu>
                <!-- <CDropdownItem
                  class="custom-dropdown-item"
                  style="cursor: pointer"
                >
                  <CIcon icon="cilUser" /> Ubah Profil
                </CDropdownItem>
                <CDropdownItem
                  class="custom-dropdown-item"
                  style="cursor: pointer"
                >
                  <CIcon icon="cilLanguage" /> ENG
                </CDropdownItem> -->
                <CDropdownItem
                  class="custom-dropdown-item"
                  style="cursor: pointer"
                  @click="
                    () => {
                      this.$router.replace('/akun/profilku/ubahpassword');
                    }
                  "
                >
                  <CIcon icon="cilSettings" />
                  Pengaturan Akun
                </CDropdownItem>
                <!-- <CDropdownItem
                  class="custom-dropdown-item"
                  style="cursor: pointer"
                >
                  <CIcon icon="cilBell" /> Pengaturan Notifikasi
                </CDropdownItem> -->
                <CDropdownItem
                  class="custom-dropdown-item"
                  style="cursor: pointer; background-color: activeColor"
                  @click="logout"
                >
                  <CIcon icon="cil-lock-locked" /> Logout
                </CDropdownItem>
              </CDropdownMenu>
            </CDropdown>
          </CHeaderNav>
        </div>
      </div>
    </CContainer>
  </CHeader>
</template>

<script>
import avatar from "@/assets/images/avatars/profile.png";
import AppHeaderDropdownAccnt from "./AppHeaderDropdownAccnt";
import { logo } from "@/assets/brand/logo";
import axios from "axios";

export default {
  name: "AppHeader",
  components: {
    AppHeaderDropdownAccnt,
  },
  setup() {
    return {
      logo,
      avatar,
    };
  },

  data() {
    return {
      role: sessionStorage.getItem("role"),
    };
  },

  methods: {
    async logout() {
      try {
        if (sessionStorage.getItem("role") === "Camaba") {
          sessionStorage.clear();
          this.$router.replace("/pages/admission");
        } else {
          this.$store.dispatch("logout");
        }
      } catch (error) {
        console.error("Error:", error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.active {
  background-color: #59359a;
  outline: none !important; /* Menyembunyikan outline/focus border */
}

.menu:focus {
  background-color: #59359a;
  outline: none !important; /* Menyembunyikan outline/focus border */
}
</style>
